import {React , useEffect} from "react";
import Aos from 'aos'
import 'aos/dist/aos.css'


function Tile(props){
    
    useEffect(() => {
        Aos.init({duration: 2000})
    },[]);

    return(
        <div data-aos="fade-left">
            <div className='py-[1px] shadow-lg shadow-[#000000] sm:hover:scale-110 sm:duration-500'>
                <p className='mt-4'>{props.name}</p>
                <img src = {props.image} alt='css-icon' className = 'w-28 mx-auto mt-4'/>
                <p className='text-sm md:text-lg my-4'>{props.msg} </p>
            </div>
        </div>
    )

}

export default Tile;