import About from "./components/About";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import {Routes, Route} from 'react-router-dom';
import Footer from "./components/Footer";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Artifacts from "./components/Artifacts";

function App() {
  return (
    <div>
     <Navbar className = 'z10'/>
     <Routes>
      <Route path = '/' element = {<Home />}/>
      <Route path = '/about' element = {<About title='About'/>}/>
      <Route path = '/skills' element = {<Skills title='Skills'/>}/>
      <Route path = '/projects' element = {<Projects title='Projects'/>}/>
      <Route path = '/artifacts' element = {<Artifacts title='Artifacts'/>}/>
      <Route path = '/contact' element = {<Contact title='Contact'/>}/>
     </Routes>
    
     
    
    </div>
  );
}

export default App;
