import React, { useEffect } from 'react';
import Aos from 'aos'
import 'aos/dist/aos.css'
import Title from './Title';

function About(props){
    useEffect(() => {
        document.title = 'About | Aarin Jasikumar';
    }, []);
    
    
    useEffect(() => {
        Aos.init({duration: 2000})
    },[]);

      return(
        <div className = 'w-full h-full bg-[#212121] text-gray-300 min-h-[900px]'>
            <div className = 'max-w-[1200px] mx-auto px-8 h-full pb-[100px]'>
                <Title title={props.title.toUpperCase()}/>
                

                <div className = 'grid grid-cols-1 place-items-start gap-y-10'>
                    <div data-aos = 'fade-left' className = 'flex flex-col sm:grid sm:grid-cols-2 sm:place-items-start gap-x-4'>
                        <p className = 'sm:text-right text-4xl font-bold w-full' >Who am I?</p>
                        <p>My name is Aarin Jasikumar I am a Sri-Lankan Canadian, born and raised in Ontario, Canada. I am currently 19 years old, attending the Computer Science Program at the University of Toronto and specializing in software engineering. I enjoy playing basketball, volleyball and weightlifting during my free time. I am currently seeking a 4-month or 8-month internship for Summer 2024.</p>
                    </div>

                    <div data-aos = 'fade-right' className = 'flex flex-col-reverse sm:grid sm:grid-cols-2 sm:place-items-start  gap-x-4'>
                        <p>During the winter semester, I am currently working on a Wordle application that is a clone of the Wordle from the New York Times. I am developing this project in React to strengthen my knowledge of this framework. I am also working on a full-stack website which uses React for a small business.</p>
                        <p className = 'sm:text-left text-4xl font-bold w-full' >What am I working on?</p>
                    </div>

                </div>
        
            </div> 
            
        </div>
    )



}

export default About;