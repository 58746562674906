import React, { useEffect } from 'react'
import {HiArrowNarrowRight} from 'react-icons/hi'
import Aos from 'aos'
import 'aos/dist/aos.css'
import Social from './Social';

function Home(){

    useEffect(() => {
        Aos.init({duration: 2000})
    },[]);

    useEffect(() => {
        document.title = 'Aarin Jasikumar';
      }, []);

    return(
        <div  className = 'w-full h-full bg-[#212121] text-gray-300 sm:min-h-[900px]'>
            <div data-aos = 'zoom-out' className = 'max-w-[1200px] mx-auto px-8 h-full pb-[100px]'> 
                <p className = 'pt-[100px] sm:pt-[200px] text-blue-400'>Hello my name is </p>
                <h1 className = 'text-4xl sm:text-7xl font-extrabold text-[#ccd6f6]'>Aarin Jasikumar</h1>
                <h2 className = 'text-4xl sm:text-7xl font-extrabold text-[#8892b0]' >I am an aspiring software developer.</h2>
                <p className = 'text-[#8892b0] py-4 max-w-[600px]'>
                    I am currently a third year computer science student
                    at the University of Toronto. I am currently working on the
                    SDDH Data Hub Project as a Full-Stack Software Engineer.
                </p>
                <a href = '/projects'>
                    <button className = 'text-white border-2 px-6 py-3 my-2 flex items-center rounded hover:bg-blue-400 hover:border-blue-400'>My Work <HiArrowNarrowRight className = 'ml-3'/></button>
                </a>
                <Social/>
    
                
           
            </div>



        </div>
    )
}

export default Home