import React, {useState} from 'react'
import Logo from '../assets/logo_color_blue.png'
import {FaBars,FaTimes} from 'react-icons/fa'
import '../styles/Navbar.css'
import PDF from '../assets/Resume.pdf'




function Navbar(){
    const [nav,setNav] = useState(false)
    const handleClick = () => setNav(!nav)


    return(
        <div className = 'fixed w-screen h-[80px] flex justify-between items-center px-4 bg-[#212121] text-white z-10'>
            <a href = '/'>
                <img src={Logo} alt = 'Logo' style = {{width: '50px'}}/>
            </a>

            {/* Menu */}

            <div className = 'hidden md:flex w-screen justify-between'>
                <ul className = "w-full flex justify-center items-center space-x-[60px]">
                    <il className = 'navElement' >
                        <a href='/'>Home</a>
                    </il>
                    <il className = 'navElement' >
                        <a href='/about'>About</a>
                    </il>
                    <il className = 'navElement' >
                        <a href='/skills'>Skills</a>
                    </il>
                    <il className = 'navElement' >
                        <a href='/projects'>Projects</a>
                    </il>
                    <il className = 'navElement' >
                        <a href='/artifacts'>Artifacts</a>
                    </il>
                    <il className = 'navElement'>
                        <a href='/contact'>Contact</a>
                    </il>
                   
                   
                </ul>
            
                <ul className ='mr-0'>
                    <il className = 'text-3xl border-2 p-2 rounded hover:bg-blue-400 hover:border-blue-400'>
                        <a href={PDF}>R
                        <span className = 'text-lg'>RESUME</span></a>
                    </il>
                </ul>
              
            
            </div>
            
            {/* hamburger */}
            <div className = 'md:hidden flex justify-end w-full'>
                <div onClick = {handleClick} className = "items-center z-10">
                    {!nav ? <FaBars /> : <FaTimes/>}
                </div>
            </div>
         
        
            {/* Mobile Menu */}
            <ul className ={!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-[#212121] flex flex-col justify-center items-center'} >
              
                <il className = 'py-6 text-4xl' >
                    <a href='/'>Home</a>
                </il>
                <il className = 'py-6 text-4xl' >
                    <a href='/about'>About</a>
                </il>
                <il className = 'py-6 text-4xl' >
                    <a href='/skills'>Skills</a>
                </il>
                <il className = 'py-6 text-4xl' >
                    <a href='/projects'>Projects</a>
                </il>
                <il className = 'py-6 text-4xl' >
                    <a href='/artifacts'>Artifacts</a>
                </il>
                <il className = 'py-6 text-4xl' >
                    <a href='/contact'>Contact</a>
                </il>
                <il className = 'mt-2 px-2 text-4xl border-2 py-2 rounded hover:bg-blue-400 hover:border-blue-400' >
                    <a href={PDF}>RESUME</a>
                </il>
                    
            </ul>
          

            {/* Social Icons */}
            <div>

            </div>

        </div>
        
       
    )

}

export default Navbar;