import React, { useEffect } from 'react'
import Title from './Title';
import CSS from '../assets/css.png'
import HTML from '../assets/html.png'
import PYTHON from '../assets/python.png'
import DATABASE from '../assets/database.png'
import JAVA from '../assets/java.png'
import REACT from '../assets/react.png'
import TAILWIND from '../assets/tailwind.png'
import JS from '../assets/js.png'
import C from '../assets/c.png'
import Eclipse from '../assets/eclipse.png'
import Android from '../assets/android.png'
import VS from '../assets/vs.png'
import Github from '../assets/github.png'
import Bash from '../assets/bash.png'
import MySQL from '../assets/mysql.png'
import Firebase from '../assets/firebase.png'

import Tile from './Tile';

function Skills(props){

    useEffect(() => {
        document.title = 'Skills | Aarin Jasikumar';
    }, []);
  
    return(
        <div className = 'w-full h-full bg-[#212121] text-gray-300 min-h-[900px]'>
            <div className = 'max-w-[1200px] mx-auto px-8 h-full pb-[100px]'> 
                <Title title={props.title.toUpperCase()}/>
                <div className = 'min-w-min w-[375px] sm:text-3xl text-2xl font-bold mb-[30px]'>
                    Programming Languages
                </div>
                
                <div className = 'w-full grid grid-cols-2 lg:grid-cols-4 sm:grid-cols-3 gap-4 text-center pb-8'>
                    <Tile 
                        name = 'Java'
                        image = {JAVA}
                        msg = '4+ Years of Experience'
                    />
                    <Tile 
                        name = 'Python'
                        image = {PYTHON}
                        msg = '5+ Years of Experience'
                    />
                    <Tile 
                        name = 'C Programming'
                        image = {C}
                        msg = '2+ Years of Experience'
                    />
                   
                    <Tile 
                        name = 'SQL'
                        image = {DATABASE}
                        msg = '2+ Years of Experience'
                    />
                    <Tile 
                        name = 'React'
                        image = {REACT}
                        msg = '1+ Years of Experience'
                    />
                    <Tile
                        name = 'HTML'
                        image = {HTML}
                        msg = '5+ Years of Experience'
                    />
                    <Tile 
                        name = 'CSS'
                        image = {CSS}
                        msg = '5+ Years of Experience'
                    />
                    <Tile 
                        name = 'Javascript'
                        image = {JS}
                        msg = '4+ Years of Experience'
                    />
                    <Tile 
                        name = 'Shell/Bash Script'
                        image = {Bash}
                        msg = '1+ Years of Experience'
                    />
                </div>
                <div className = 'min-w-min w-[375px] sm:text-3xl text-2xl font-bold my-[30px]'>
                    Technologies
                </div>
                <div className = 'w-full grid grid-cols-2 lg:grid-cols-4 sm:grid-cols-3 gap-4 text-center pb-8'>
                     <Tile 
                        name = 'Eclipse'
                        image = {Eclipse}
                        msg = '4+ Years of Experience'
                    />
                    <Tile 
                        name = 'Android Studio'
                        image = {Android}
                        msg = '2+ Years of Experience'
                    />
                    <Tile 
                        name = 'Tailwind'
                        image = {TAILWIND}
                        msg = '1+ Years of Experience'
                    />
                    <Tile 
                        name = 'VS Code'
                        image = {VS}
                        msg = '3+ Years of Experience'
                    />
                    <Tile 
                        name = 'Github'
                        image = {Github}
                        msg = '3+ Years of Experience'
                    />
                     <Tile 
                        name = 'MySQL'
                        image = {MySQL}
                        msg = '1+ Years of Experience'
                    />
                    <Tile 
                        name = 'Firebase (NoSQL and Hosting)'
                        image = {Firebase}
                        msg = '2+ Years of Experience'
                    />
                </div>
                
            
        
            </div> 
            
        </div>
    )



}

export default Skills;